import React, { useEffect, useState, createContext, useContext } from "react";
import firebase from "firebase/app";
import "firebase/messaging";

// Firebase Configuration
const firebaseConfig = {
  // apiKey: "AIzaSyBHPFJve3yR63X4ExV2fW0XZRNouKoth10",
  // authDomain: "testing-d06ae.firebaseapp.com",
  // projectId: "testing-d06ae",
  // storageBucket: "testing-d06ae.appspot.com",
  // messagingSenderId: "914878232413",
  // appId: "1:914878232413:web:9f277e0374a57ab1b61ab4",
  // measurementId: "G-9KYTQ8NJ0M",
  apiKey: "AIzaSyDpsj6LAIHhGJo3Wo3aSnJUuo-ELeUhG9E",
  authDomain: "fir-project-25f3c.firebaseapp.com",
  projectId: "fir-project-25f3c",
  storageBucket: "fir-project-25f3c.appspot.com",
  messagingSenderId: "1096638966120",
  appId: "1:1096638966120:web:41846b13737de348fa0edd",
  measurementId: "G-MV5GWF1BZB",
};

// Firebase Initialization
firebase.initializeApp(firebaseConfig);

// Custom hook to use Firebase messaging
const useFirebaseMessaging = () => {
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState(null);
  console.log(message, token, "checkfirebase");

  useEffect(() => {
    const messaging = firebase.messaging();

    // Get FCM token
    const getToken = async () => {
      try {
        const currentToken = await messaging.getToken();
        if (currentToken) {
          setToken(currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      } catch (error) {
        console.log("An error occurred while retrieving token. ", error);
      }
    };

    // Handle incoming messages
    const onMessageListener = () => {
      messaging.onMessage((payload) => {
        console.log(payload , "firebaseContext")
        setMessage(payload);
      });
    };

    getToken();
    onMessageListener();
  }, []);

  return { token, message };
};

// Context to provide Firebase messaging data
export const FirebaseContext = createContext({ token: "", message: "" });

export const FirebaseProvider = ({ children }) => {
  const firebaseMessaging = useFirebaseMessaging();
  console.log(firebaseMessaging.message , "firebaseContext")
  return (
    <FirebaseContext.Provider value={firebaseMessaging}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  return useContext(FirebaseContext);
};
