import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch, HashRouter, withRouter } from "react-router-dom";

import configureStore, { history } from "./store";
import App from "./containers/App";
import { SocketProvider } from "./containers/socket";
import { FirebaseProvider } from "./firebase/firebase";

export const store = configureStore();

const MainApp = () => (
  <FirebaseProvider>
    <SocketProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <HashRouter>
              <Route path="/" component={App} />
            </HashRouter>
          </Switch>
        </ConnectedRouter>
      </Provider>
    </SocketProvider>
  </FirebaseProvider>
);

export default MainApp;
