import React, { useEffect, useMemo, useState } from "react";

import SocketIOClient from "socket.io-client";

const baseApiUrl = "https://api.imsohungry.com/";

export const SocketContext = React.createContext({
  isConnected: false,
  socket: null,
});
export function SocketProvider({ children }) {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const socketInt = SocketIOClient(baseApiUrl, {
      transports: ["polling"],
    });

    connectSocket(socketInt);

    socketInt.on("error", (error) => {
      setIsConnected(false);
      console.error("Socket connection error:", error);
    });

    setSocket(socketInt);
    return () => {
      if (socketInt) {
        socketInt.disconnect();
        setIsConnected(false);
      }
    };
  }, []);

  const connectSocket = (socketInt) => {
    socketInt.on("connect", () => {
      setIsConnected(true);
      const restId = localStorage.getItem("_id");
      if (restId) {
        socketInt.emit(
          "restaurantsocket",
          { restaurantId: restId },
          (response) => {
            console.log("response=====>", response);
          }
        );
      }
      console.log("Socket connected");
    });
  };

  const values = useMemo(
    () => ({
      isConnected,
      socket,
    }),
    [isConnected, socket]
  );

  return (
    <SocketContext.Provider value={values}>{children}</SocketContext.Provider>
  );
}
