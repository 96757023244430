// Api BASE URLs
export const API_URL = "https://api.imsohungry.com";

//////********** TESTING URL *********************/

// export const API_URL = "https://staging-api.imsohungry.com";
export const API_VERSION = "/api/v1";

// COMMON CONSTANT
export const USER_TYPE = "Restaurant";

export const APP_NAME = "I'm So Hungry";

export const API_TOKEN = localStorage.getItem("user_id");
export const VENDOR_ID = localStorage.getItem("_id");
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PASS_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const Numeric = /^[0-9]+$/;
export const ALPHABET = /^[A-Za-z -]+$/;
export const PASS_VALIDATION_MSG =
  "password should be at least 8 characters, one uppercase, lowercase, special character, numeric value.";

// AUTH URL
export const API_LOGIN = "/store/storelogin";
export const API_FORGOT_PASSWORD = "/restaurant/forgotpassword";
export const API_RESET_PASSWORD = "/restaurant/resetpassword";

// DASHBOARD
export const DASHBOARD = "/store/getDashboardList";
export const API_UPADTE_STORE_STATUS = "/store/storeUpdateOnlineOfflineStatus";
// Stores
export const API_GET_STORES_URL = "/store/";
export const API_GET_STORES_BY_ID = "/store/getstorebyId/";
export const API_GET_SUB_CATEGORIES = "/category/getCategoryBySubCat/";
export const API_ADD_STORES = "/store/register/";
export const API_EDIT_STORES = "/store/updatestore/";
export const API_ADD_STORE_BANKAC = "/store/addaccount/";
export const API_ADD_STORE_DOCS = "/store/addStoreDoc/";
export const API_GET_STORE_DOCS = "/store/getStoreDoc/";
export const API_UPLOAD_STORE_ITEMS = "/store/uploadStoreItem/";
export const API_GET_UNITS = "/adminUnit/getEnabledUnitList";
export const API_GET_MENU_BY_CATEGORY = "/admin/menu/items";
export const API_GET_MENU_ITEMS = "/admin/menu/items";

// Store's Items
export const API_GET_STORES_ITEMS = "/store/getAllStoreItem/";
export const API_GET_STORES_ITEM_BY_ID = "/store/getItemById/";
export const API_ADD_STORES_ITEM = "/store/addStoreItem/";
export const API_EDIT_STORES_ITEM = "/store/updateStoreItem";
export const API_DELETE_STORES_ITEM = "/store/deleteStoreItem";
export const API_UPDATE_STORE_ITEM_STATUS = "/store/updateItemStatus";

//Menu Item Drag
export const API_MENU_VIEW_URL = "/store/storemenuitems";
export const API_RESTAURANTS_MENU_UPDATE_URL =
  "/admin/restaurant/changeMenuItemOrder";
export const API_RESTAURANTS_ITEM_UPDATE_URL =
  "/admin/restaurant/changeItemsOrder";

// Fresh Market Store's Items
export const API_GET_FRESHMARKET_STORES_ITEMS =
  "/restaurant/getallfreshMarketItems/";
export const API_GET_FRESHMARKET_STORES_ITEM_BY_ID =
  "/restaurant/getfreshmarketItemById/";
export const API_ADD_FRESHMARKET_STORES_ITEM =
  "/restaurant/addFreshmarketItem/";
export const API_EDIT_FRESHMARKET_STORES_ITEM =
  "/restaurant/editFreshmarketItem/";
export const API_DELETE_FRESHMARKET_STORES_ITEM =
  "/restaurant/deleteFreshmarketItem/";

export const API_GET_FRESHMARKET_CATEGORIES_URL = "/admin/freshCategoryList/";

// Drivers
export const API_GET_DRIVER_URL = "/store/getDriverList/";
export const API_GET_DRIVER = "/admin/getDriverById/";
export const API_ADD_DRIVER = "/admin/addDriver/";
export const API_EDIT_DRIVER = "/admin/updateDriver/";
export const API_DELETE_DRIVER = "/admin/deleteDriver/";

// Category
export const API_GET_CATEGORY_URL = "/category/";
export const API_GET_FRESHMARKET_CATEGORY_URL = "admin/freshCategoryList/";
export const API_GET_CATEGORY = "/category/edit/";
export const API_ADD_CATEGORY = "/category/add/";
export const API_EDIT_CATEGORY = "/category/update/";
export const API_DELETE_CATEGORY = "/category/remove/";
export const API_GET_SUB_STORES_CATEGORIES = "/category/getCategoryBySubCat/";
export const API_GET_STORE_CATEGORY_URL = "/storecategory/";
export const API_GET_MENU_URL = "/store/menuItem";

//Addons Category
export const API_GET_ADDONS_CATEGORY = "/category/addonscategorylisting";

// Promo Code URLs
export const API_GET_ALL_PROMOCODE = "/adminSetting/getPromoCodeList/";
export const API_ADD_PROMOCODE = "/adminSetting/addPromoCode/";
export const API_EDIT_PROMOCODE = "/adminSetting/editPromocode/";
export const API_UPDATE_PROMOCODE_STATUS =
  "/adminSetting/updatePromocodeStatus/";
export const API_GET_PROMOCODE = "/adminSetting/getPromocodeDetails/";
export const API_DELETE_PROMOCODE = "/adminSetting/promocodeDelete/";
export const API_RESTURANT_TRANSATION = "/store/restaurantpaymentshistory";

// Push Notification URLs
export const SEND_PUSH_NOTIFICATION = "admin/sendNotification";

// Settings
export const API_UPDATE_ADMIN_PASSWORD = "/store/changePassword/";
export const API_GET_BASIC_SETTINGS = "/adminSetting/getBasicSettingInfo";

//orders

export const API_GET_ORDERS = "/store/storeOrdersWithFilter/";
export const API_GET_ORDER_BY_ID = "/store/orderdetails/";
export const API_GET_ORDER_ACCEPT = "/store/acceptorder/";
export const API_GET_ORDER_REJECT = "/store/rejectorder/";
export const API_GET_ORDER_MARK = "/store/markorderready/";

export const MAKE_TOP_RATED = "/restaurant/maketoprated";
export const API_UPDATE_LOCATION = "/restaurant/updateLocation";
export const FILE_UPLOAD = "/adminSetting/uploadFile";

//Main Menu
export const API_GET_MAIN_MENU_URL = "/admin/menuitem/";
export const API_GET_MENU_BY_ID = "/admin/menuitem/view/";
export const API_ADD_MENU = "/admin/menuitem/add/";
export const API_EDIT_MENU = "/admin/menuitem/update/";
export const API_DELETE_MENU = "/admin/menuitem/remove/";
