import React, { useState } from "react";
import Link from "@material-ui/core/Link";
import { Row, Col, Container, Button } from "react-bootstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";
import {
  connect,
  Axios,
  SELECT,
  PHONECODES,
  Select,
  ToastContainer,
  toast,
} from "../../components";
import WhyOurBrand from "../../components/auth/authBody/WhyOurBrand/Index";
import ImgWithText from "../../components/auth/authBody/ImageWithText/Index";
import HowWeWork from "../../components/auth/authBody/HowWeWork/Index";
import OurClient from "../../components/auth/authBody/OurClient/Index";
import GetStartedSteps from "../../components/auth/authBody/GetStartedSteps/Index";
import Faq from "../../components/auth/authBody/Faq/Index";
import Footer from "../../components/auth/authBody/Footer/Index";
import CssBaseline from "@material-ui/core/CssBaseline";

import Cactus from "../../assets/images/cactus.png";
import { API_ADD_STORES } from "../../ApiUrl";

const useStyles = (theme) => ({});
const Signup = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    managerName: "",
    countryCode: "",
    mobileNumber: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    restaurantType: "restaurant",
    newSignIn: true,
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const validate = () => {
    let errors = {};
    if (!formValues.name) errors.name = "Restaurant name is required";
    if (!formValues.email) {
      errors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formValues.countryCode)
      errors.countryCode = "Country code is required";
    if (!formValues.managerName)
      errors.managerName = "Owner/Manager name is required";
    if (!formValues.mobileNumber)
      errors.mobileNumber = "Contact phone number is required";
    if (!formValues.address) errors.address = "Address is required";
    if (!formValues.city) errors.city = "City is required";
    if (!formValues.state) errors.state = "State is required";
    if (!formValues.zipCode) errors.zipCode = "Zip code is required";
    return errors;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      console.log("Form submitted successfully", formValues);

      let formData = new FormData();
      for (var key in formValues) {
        formData.append(key, formValues[key]);
      }
      Axios.post(API_ADD_STORES, formData)
        .then((res) => {
          let response = res.data;

          console.log("response", response);
          const { status, message } = response;
          if (status === "failure") {
            console.log("status", status);
            toast.error(message);
            // return false;
          } else {
            toast.success(response.message);

            setTimeout(() => {
              history.push("/");
            }, 1000);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          let res = error.response;
          toast.error(res.data.message);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setFormErrors(errors);
    }
  };
  return (
    <>
      <section className="signup_page">
        <div className="signup_banner"></div>

        <div className="signup_fiels py-lg-5 py-3 position-relative">
          <Container>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <div className="signup_left">
                  <h5>
                    <strong>Tucsons Local Food Delivery Service</strong>
                  </h5>
                  <div className="save_money_suppoort">
                    <h1 className="text-uppercase">
                      <span>SAVE</span> Money
                    </h1>
                    <h4 className="text-uppercase">
                      And Support Our Local Business
                    </h4>
                  </div>

                  <ul className="text-start list_style">
                    <li>
                      The others commissions - <strong>20% fa 25%</strong>
                    </li>
                    <li>
                      Our Commissions <strong>14% </strong>per order, (does not
                      include Merchant Fees)
                    </li>
                    <li>
                      {" "}
                      Sign up by July 31 and{" "}
                      <strong> get 7% Commissions for first 3 months </strong>
                    </li>
                    <li>
                      {" "}
                      Average monthly <strong>saving of $700</strong>{" "}
                    </li>
                  </ul>

                  <div className="list_wrap d-flex align-items-center justify-content-between flex-wrap mt-lg-5 mt-3">
                    <ul className="adddrees_list">
                      <li>
                        <span className="svg_icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                          >
                            <g fill="none">
                              <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                              <path
                                fill="#fff"
                                d="m2.068 5.482l8.875 8.876a1.5 1.5 0 0 0 2.008.103l.114-.103l8.869-8.87c.029.11.048.222.058.337L22 6v12a2 2 0 0 1-1.85 1.995L20 20H4a2 2 0 0 1-1.995-1.85L2 18V6c0-.12.01-.236.03-.35zM20 4c.121 0 .24.01.355.031l.17.039l-8.52 8.52l-8.523-8.522c.11-.03.224-.05.34-.06L4 4z"
                              />
                            </g>
                          </svg>
                        </span>
                        <p>Salesliltucsoneats.com</p>
                      </li>

                      <li>
                        <span className="svg_icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M19.95 21q-3.125 0-6.175-1.362t-5.55-3.863t-3.862-5.55T3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.238t.325.562l.65 3.5q.05.4-.025.675T9.4 8.45L6.975 10.9q.5.925 1.187 1.787t1.513 1.663q.775.775 1.625 1.438T13.1 17l2.35-2.35q.225-.225.588-.337t.712-.063l3.45.7q.35.1.575.363T21 15.9v4.05q0 .45-.3.75t-.75.3"
                            />
                          </svg>
                        </span>
                        <p> 520-891-1700 </p>
                      </li>
                    </ul>

                    <ul className="adddrees_list">
                      <li>
                        <span className="svg_icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="M4 21V9l8-6l8 6v12h-6v-7h-4v7z"
                            />
                          </svg>
                        </span>
                        <p> 2813 E Broadway Blvd Tucson, AZ 85716 </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <div className="signup_right_form">
                  <h5 className="text-uppercase text-center">
                    To sign up or help with question
                  </h5>

                  <form onSubmit={handleSubmit} noValidate>
                    <Row>
                      <Col lg={6} md={6} sm={12} className="px-2">
                        <FormGroup>
                          <Label for="Resturantname">Resturant Name</Label>
                          <Input
                            type="text"
                            name="name"
                            id="Resturantname"
                            placeholder=""
                            value={formValues.name}
                            onChange={handleChange}
                          />
                          {formErrors.name && (
                            <div className="error">{formErrors.name}</div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={6} md={6} sm={12} className="px-2">
                        <FormGroup>
                          <Label for="emailaddress">Email Address</Label>
                          <Input
                            type="email"
                            name="email"
                            id="emailaddress"
                            placeholder=""
                            value={formValues.email}
                            onChange={handleChange}
                          />
                          {formErrors.email && (
                            <div className="error">{formErrors.email}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md="6" xs="12" className="px-2">
                        <FormGroup>
                          <Label for="countryCode">Country Code</Label>
                          <Input
                            type="text"
                            name="countryCode"
                            id="countryCode"
                            placeholder=""
                            value={formValues.countryCode}
                            onChange={handleChange}
                          />
                          {formErrors.countryCode && (
                            <div className="error">
                              {formErrors.countryCode}
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg={6} md={6} sm={12} className="px-2">
                        <FormGroup>
                          <Label for="mobileNumber">Contact Phone Number</Label>
                          <Input
                            type="tel"
                            name="mobileNumber"
                            id="mobileNumber"
                            placeholder=""
                            value={formValues.mobileNumber}
                            onChange={handleChange}
                          />
                          {formErrors.mobileNumber && (
                            <div className="error">
                              {formErrors.mobileNumber}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={12} md={12} sm={12} className="px-2">
                        <FormGroup>
                          <Label for="managerName">Owner/Manager Name</Label>
                          <Input
                            type="text"
                            name="managerName"
                            id="managerName"
                            placeholder=""
                            value={formValues.managerName}
                            onChange={handleChange}
                          />
                          {formErrors.managerName && (
                            <div className="error">
                              {formErrors.managerName}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={12} className="px-2">
                        <FormGroup>
                          <Label for="address">Address</Label>
                          <Input
                            type="textarea"
                            name="address"
                            id="address"
                            value={formValues.address}
                            onChange={handleChange}
                          />
                          {formErrors.address && (
                            <div className="error">{formErrors.address}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg={4} md={6} sm={12} className="px-2">
                        <FormGroup>
                          <Label for="city">City</Label>
                          <Input
                            type="text"
                            name="city"
                            id="city"
                            placeholder=""
                            value={formValues.city}
                            onChange={handleChange}
                          />
                          {formErrors.city && (
                            <div className="error">{formErrors.city}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg={4} md={6} sm={6} className="px-2">
                        <FormGroup>
                          <Label for="state">State</Label>
                          <Input
                            type="text"
                            name="state"
                            id="state"
                            placeholder=""
                            value={formValues.state}
                            onChange={handleChange}
                          />
                          {formErrors.state && (
                            <div className="error">{formErrors.state}</div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={4} md={6} sm={6} className="px-2">
                        <FormGroup>
                          <Label for="zipCode">Zip</Label>
                          <Input
                            type="number"
                            name="zipCode"
                            id="zipCode"
                            placeholder=""
                            value={formValues.zipCode}
                            onChange={handleChange}
                          />
                          {formErrors.zipCode && (
                            <div className="error">{formErrors.zipCode}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg={12} md={12} sm={12} style={{ display: "flex" }}>
                        <Button
                          type="submit"
                          className="btn_send"
                          disabled={loading}
                        >
                          Send
                        </Button>
                      </Col>
                      <Col md="12" className={"d-flex justify-content-center"}>
                        <p className="m-0">
                          Already have an account?{" "}
                          <Link
                            href="#/signin"
                            className="form-link"
                            variant="body1"
                          >
                            {"Log In"}
                          </Link>
                        </p>
                      </Col>
                    </Row>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="cactus">
            <img src={Cactus} alt="" className="img-fluid" />
          </div>
        </div>
        <ToastContainer />
      </section>
      <WhyOurBrand />
      <ImgWithText />
      <HowWeWork />
      <OurClient />
      <GetStartedSteps />
      <Faq />
      <Footer />
      <CssBaseline />
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(withStyles(useStyles)(Signup));
